/*HEADER*/
header {
    background-image: url('../image/office.jpg');
    height: 70vh;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: white;
    width: 100%;
}

.search-form {
    margin-top: 1rem;
    margin-bottom: 1rem;
    width: 100%; /* Arama formunun tüm genişliği kaplaması */
    display: flex;
    justify-content: center; /* Formu ortalamak için */
}

.search-input {
    border-radius: 2rem;
    padding: 1rem 2rem; /* Daha fazla padding ile daha büyük görünsün */
    font-size: 1.25rem; /* Daha büyük font */
    width: 90%; /* Daha geniş yap */
    max-width: 800px; /* Maksimum genişlik artırıldı */
}

h1 { font-size: 3em; margin: 0.67em 0; color: white; }
h5 { font-size: 3em; margin: 0.67em 0; color: rgb(0, 0, 0); }
h4 { font-size: 2em; margin: 0.67em 0; color: #353535; }

/* MAIN SECTION */
/*SERVICES*/
#services_id{
    margin-bottom:4rem;
    margin-top: 2rem;
}

#services_id .icon_service i {
    font-size: 3rem;
    margin-bottom: 1rem;
    margin-left: 4rem;
}

#services_id .icon_service h4{
    text-transform: capitalize;
    font-weight: 600;
}

/*BLOG*/
#blog_id{
    margin-bottom: 4rem;
    margin-top: 8rem;
    padding:3rem .1rem;
    background-color: rgb(166, 165, 167);
}

#blog_id h2{
    color:white;
    margin-bottom:4rem;
}

#blog_id .blog_service .container .row .card img{
    max-width: 100%;
    height: 15rem;
    padding:0.2rem;
}

#blog_id .blog_service .container .row .card img:hover{
    transition: ease-in-out 0.2s;
    transform: scale(1.01);  
    opacity: 0.9;
}

#blog_id .blog_service .card-body h4,p{
   color:black;
}

/* ABOUT */
#about_id {
    background-image: url("../image/ooffice.jpg");
    background-color: transparent;
    background-attachment: fixed;
    background-size: cover;
    background-repeat: no-repeat;
    height: auto;
    padding: 7rem;
    font-size: large;
    color: #fff; /* Metnin arka planda okunabilir olması için renk ayarı */
}


@media (max-width: 768px) {
    #about_id {
        padding: 2rem; /* Mobil cihazlarda dolgu ayarları */
        font-size: medium; /* Daha küçük ekranlar için yazı boyutu ayarı */
    }


}

/* CONTACT */
#contact_id{
    margin-bottom: 4rem;
    margin-top: 8rem;
    padding:3rem .1rem;
}

/*BACK TO TOP*/
#backToTop{
    position: fixed;
    bottom: 0.7rem;
    right: 0.7rem;
    background-color: #000000;
    color: white;
    width: 3rem;
    height: 3rem;
    text-align: center;
    line-height: 2.5rem;
    font-size: 1.5rem;
    border-radius: 20%;
    cursor: pointer;
    /* display: none; */
}
