/*
Project Made Date: December 7 Thursday 2023
Project Made By: hamit Mızrak
Project Made For: 2023
Project Made With: HTML, CSS, JS, React, Node.js, Express.js, MongoDB, Mongoose, Redux, React-Redux, Redux-Thunk, React-Router-Dom, React-Icons, React-Bootstrap, React-Toastify, React-Quill, React-Image-File-Resizer, React-Image-Gallery, React-Player, React-Share, React-Countup, React-Chartjs-2, React-Datepicker,
Project Version: v1.0.0
Project Status: Completed
Project Description: This is a blog website. You can create, read, update and delete posts. You can also create, read, update and delete categories. You can also create, read, update and delete comments. You can also create, read, update and delete users. You can also create, read, update and delete contact messages. You can also create, read, update and delete about me. You can also create, read, update and delete social media links. You can also create, read, update and delete images. You can also create, read, update and delete videos. You can also create, read, update and delete files. You can also create, read, update and delete audios. You can also create, read, update and delete links. You can also create, read, update and delete tags. You can also create, read, update and delete pages. You can also create, read, update and delete menus. You can also create, read, update and delete menu items. You can also create, read, update and delete menu item links.
Project Website: https://hamitmizrakblog.herokuapp.com/
Project Source:
*/
/***** ROOT **********************************************************************/
:root {
  --template-bg: #414141;
  --template-color: #e3e3e3;
  --header-vh: 150vh;
  --max-width: 100%;
  --light: #f8f9fa;
  --dark: #343a40;
  --danger: #dc3545;
  --success: #28a745;
  --warning: #ffc107;
  --info: #17a2b8;
  --primary: #007bff;
  --secondary: #6c757d;
}

/***** COMMON **********************************************************************/
* {
  padding: 0;
  margin: 0 px;
  box-sizing: border-box;
}

html {
  font-size: 15px;
}

body {
  background-color: var(--body_background);
  color: var(--body_color);
  position: relative;
  line-height: 1.6;
  font-weight: 400 !important;
  font-size: 1rem !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

ul {
  text-decoration: none;
}

ul {
  list-style-type: none;
}

/* p {
  font-size: 1.2rem;
  text-align: justify;
  padding-left: 1.2rem;
  padding-right: 1.2rem;
} */

img {
  max-width: 100%;
  height: auto;
}

img:hover {
  box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, .9);
}

i {
  font-size: 1.5rem;
  opacity: 0.9
}

.my_clearfix::after {
  content: "";
  display: table;
  clear: both;
}

/***** Line Clamp **********************************************************************/
.line-clamp4 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;

  display: -moz-box;
  /* firefox,safari, chrome,opera,Edge */
  -moz-line-clamp: 4;
  -moz-box-orient: vertical;
}
.line-clamp5 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;

  display: -moz-box;
  /* firefox,safari, chrome,opera,Edge */
  -moz-line-clamp: 5;
  -moz-box-orient: vertical;
}

.line-clamp8 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 8;
  -webkit-box-orient: vertical;

  display: -moz-box;
  /* firefox,safari, chrome,opera,Edge */
  -moz-line-clamp: 8;
  -moz-box-orient: vertical;
}

.line-clamp10 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 10;
  -webkit-box-orient: vertical;

  display: -moz-box;
  /* firefox,safari, chrome,opera,Edge */
  -moz-line-clamp: 10;
  -moz-box-orient: vertical;
}

.line-clamp15 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 15;
  -webkit-box-orient: vertical;

  display: -moz-box;
  /* firefox,safari, chrome,opera,Edge */
  -moz-line-clamp: 15;
  -moz-box-orient: vertical;
}

/***** Dark Mode **********************************************************************/

/* dark mode */
:root {
  --body_background: #e3e3e3 !important;
  --body_color: #414141 !important;
}

/* dark mode */
[data-theme="dark"] {
  --body_background: #414141 !important;
  --body_color: #e3e3e3 !important;
}

/* dark mode */
* {
  transition: all 0.3 ease-in-out;
}

/********************************************/
/* NAVBAR */
/* navbar first */
#navbar_first_id {
  max-height: 4rem;
  max-width: 100% !important;
  background-color: black;
  color: white;
  opacity: 0.8;
  position: sticky;
  top: 0;
}

#navbar_first_id ul li {
  display: inline;
}

#navbar_first_id ul li a {
  margin-right: 0.1rem;
}

#navbar_first_id i {
  font-size: 1.2rem;
  color: #dc3545;
  margin-right: 0.5rem;
}

#navbar_first_left {
  float: left;
}

#navbar_first_right {
  float: right;
}

/* Media Query */
@media screen and (max-width:575px) {
  #navbar_first_right {
    /* display: none; */
    margin-top: -2.8rem;
  }
}

/* navbar second */
#navbar_second_id {
  position: sticky!important;
  top: 10!important;
}

/********************************************/